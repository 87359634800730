<script setup lang="ts">
import { useIntercomSetup } from '~/composables/useIntercomSetup'

import { useMiscResizeStore } from '~/stores/miscResize'

useIntercomSetup()

const { SETUP_EVENT_LISTENERS, COMPUTE_AND_SET_SCREEN_SIZE } =
  useMiscResizeStore()

onMounted(() => {
  COMPUTE_AND_SET_SCREEN_SIZE()
  SETUP_EVENT_LISTENERS()
})
</script>

<template>
  <div class="layoutWrapper barren">
    <slot />
  </div>
</template>
